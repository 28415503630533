<template>
  <ion-page class="page" :class="{ 'store-page': !ringShopQuery }">
    <div v-if="ringShopQuery" class="shop">
      <span @click="goBack" class="clickable-item-hov">CharacterHub Store</span> >
      <span style="color: var(--ion-color-primary)">Profile Rings Shop</span>
    </div>

    <h2 class="header-text mx-auto my-3">{{ ringShopQuery ? 'Profile Rings Shop' : 'CharacterHub Store' }}</h2>
    <ch-loading class="mx-auto mt-5" v-if="loading" size="lg" />
    <div v-else class="w-100 shop-inner">
      <CurrencyBar
        v-if="featureFlags.currency && !loading"
        :coins="get(usersCurrency, 'coins')"
        :gems="get(usersCurrency, 'gems')"
      />
      <div v-if="ringShopQuery">
        <ProfileRingsShop :profileRingCategories="prfRingCategories" :showBgClr="true" @updated="fetchProfileRings" />
      </div>
      <div v-else class="shop-inner">
        <StoreMainPage
          :items="items"
          :specialOffers="specialOffers"
          :rings="rings"
          :gemPacks="gemPacks"
          @updated="fetchProfileRings"
          @fetchVirtualItems="getFeaturedVirtualItems"
          @fetchGemListings="fetchGemShopListings"
        />
        <!-- <StoreTabs class="py-3" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" /> -->
        <div class="my-5"></div>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { featureFlags } from '@/shared/config/feature-flags';
import { authStore } from '@/shared/pinia-store/auth';
import ProfileRingsShop from '@/shared/pages/store/ProfileRingsShop.vue';
import StoreMainPage from '@/shared/pages/store/StoreMainPage.vue';
import CurrencyBar from './store/CurrencyBar.vue';
import { getFeaturedVirtualItems, getVirtualItemsCategories } from '@/shared/actions/virtualItems';
import { getGemShopListings, getGemPackageListings } from '@/shared/actions/payments';

const { getUserFreebieDetails, getUsersCurrency, usersCurrency } = authStore();

const rings = ref([]);
const items = ref([]);
const gemPacks = ref([]);
const specialOffers = ref([]);
const loading = ref(true);

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});

const route = useRoute();
const router = useRouter();
const prfRingCategories: any = ref([]);
const { hydrateUser } = authStore();
const ringShopQuery = computed(() => {
  return get(route.query, 'tab');
});

const goBack = () => {
  router.replace({ name: 'shop' });
};

const fetchCurrencyStatus = () => {
  getUsersCurrency();
  getUserFreebieDetails();
};

const fetchProfileRings = () => {
  fetchFeaturedProfileRings();
  getUsersCurrency();
  hydrateUser();
};

const fetchFeaturedProfileRings = async () => {
  const resp = await getFeaturedVirtualItems();
  rings.value = resp;
  if (rings.value) {
    rings.value = rings.value.filter(
      (val: any) =>
        isNull(get(val, 'in_store_till')) || new Date().getTime() <= new Date(get(val, 'in_store_till')).getTime()
    );
  }
};

const fetchProfileRingCategories = async () => {
  const resp = await getVirtualItemsCategories({ virtual_group: 'Profile Ring' });
  prfRingCategories.value = resp;
};

const fetchGemShopListings = async () => {
  getGemPackageListings().then((res: any) => {
    gemPacks.value = res.bundles;
    specialOffers.value = res.special;
  });
};

onMounted(async () => {
  getGemShopListings().then((res: any) => {
    items.value = res;
  });
  getGemPackageListings().then((res: any) => {
    gemPacks.value = res.bundles;
    specialOffers.value = res.special;
  });
  fetchCurrencyStatus();
  if (featureFlags.profileRing) {
    fetchFeaturedProfileRings();
    fetchProfileRingCategories();
  }
  setTimeout(() => {
    loading.value = false;
  }, 50);
});
</script>

<style lang="sass" scoped>
.shop
  margin-top: 1.5rem
.shop-inner
  max-width: 706px
  margin: 0 auto

.header-text
  font-family: Roboto Slab, sans-serif
  font-weight: bold
  font-size: 26px
.store-page
  display: flex
  align-items: center
</style>
